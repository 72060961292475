import { useState, useEffect } from "react";
import {
  Sidebar as ProSidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { IoBasket } from "react-icons/io5";
import { MdOutlineQuiz } from "react-icons/md";
import { BsFillGridFill } from "react-icons/bs";
import { TbBasketFilled } from "react-icons/tb";
import { BiSolidCategory } from "react-icons/bi";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  return (
    <MenuItem
      active={selected === title}
      style={{
        width: "100%",
        height: "44px",
        backgroundColor: "transparent",
        borderRadius: "4px",
        cursor: "pointer",
        paddingLeft: "18px",
        paddingRight: "18px",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
      className="menu-item"
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

function Sidebar() {
  const { collapseSidebar } = useProSidebar();

  const theme = useTheme();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [sidebarHeight, setSidebarHeight] = useState("100vh");

  useEffect(() => {
    const screenHeight = window.innerHeight;
    setSidebarHeight(`${screenHeight}px`);
  }, []);
  return (
    <Box
      sx={{
        "& .ps-sidebar-container": {
          background: "white !important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .ps-menuitem-root": {
          color: "#5F748D",
        },
        "& .ps-menuitem-root.ps-active": {
          color: "rgb(202, 138, 4 , 1 ) !important",
          background: "rgb(202, 138, 4 , 0.2 ) !important",
          borderRadius: "8px",
        },
        "& .MuiBox-root": {
          paddingX: 2,
        },
        "& .ps-menu-icon": {
          padding: "5px",
          marginRight: "5px",
        },
        "& .ps-menu-button": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        "& .menu-item": {
          color: "#5F748D",
        },
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        style={{ height: sidebarHeight, width: "280px", overflowY: "hidden" }}
      >
        <Menu iconShape="square">
          <MenuItem
            onClick={() => {
              setIsCollapsed(!isCollapsed);
              collapseSidebar();
            }}
            icon={isCollapsed ? <MdOutlineQuiz /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "gray",
              height: isCollapsed ? undefined : "50px",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent=""
                alignItems="center"
                ml="-10px"
                height={"50px"}
              >
                <p
                  style={{
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "40px",
                    fontWeight: "600",
                    color: "#121F43",
                  }}
                >
                  {" "}
                  <span style={{ color: "rgb(202, 138, 4 )" }}>A</span>
                  lniya
                </p>
              </Box>
            )}
          </MenuItem>
          <Box>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<BsFillGridFill />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Products"
              to="/adminproducts"
              icon={<TbBasketFilled />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Category"
              to="/category"
              icon={<BiSolidCategory />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default Sidebar;
