import { apiSlice } from "../apiSlice";

const PRODUCT_URL = "api/product";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchProducts: builder.query({
      query: () => `${PRODUCT_URL}/`,
    }),
    fetchProduct: builder.query({
      query: (id) => `${PRODUCT_URL}/get/${id}`,
    }),
    createProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCT_URL}/`,
        method: "POST",
        body: data,
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `${PRODUCT_URL}/update/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_URL}/delete/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchProductQuery,
  useFetchProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productApiSlice;
