import React from 'react';
import logo from '../assets/images/logo.png';
import user from '../assets/icons/user.png';
import malawi from '../assets/icons/malawi_16px.png';
import { CiTwitter, CiFacebook } from 'react-icons/ci';
import { SlSocialInstagram } from 'react-icons/sl';
import { BsSearch, BsCart } from 'react-icons/bs'
import { VscAccount } from 'react-icons/vsc'
import { AiOutlineHeart } from 'react-icons/ai'
import { TfiHelp } from 'react-icons/tfi'

function Navbar() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);
    const [isDropdownOpen, setDropdownOpen] = React.useState(false);
    const [isScrolled, setIsScrolled] = React.useState(false);

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogin = () => {
        // Logic to handle user login
        setIsUserLoggedIn(true);
    };

    const handleLogout = () => {
        // Logic to handle user logout
        setIsUserLoggedIn(false);
    };

    return (
        <div>
            <nav className={`bg-white border-b-2 ${isScrolled ? 'hidden' : ''}`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-8">
                        <div className="flex items-center">
                            <img src={malawi} alt="" className="w-5 h-15" />
                            <p className="text-xs text-gray-500">/ English / MWK</p>
                        </div>
                        <div className="flex items-center justify-around text-gray-600 text-xs w-96">
                            <p className="flex items-center px-1">
                                Sell on alniya
                            </p> |
                            <p className="flex items-center px-1">
                                <AiOutlineHeart className="w-5 h-5 text-gray-600 pr-1" /> Wish List
                            </p>|
                            <p className="flex items-center px-1">
                                <TfiHelp className="w-4 h-4 text-gray-600 pr-1" /> Help
                            </p>|
                            <p className="flex items-center px-1">
                                <VscAccount className="w-4 h-4 text-gray-600 pr-1" /> Account
                            </p>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className={`bg-white ${isScrolled ? 'fixed top-0 w-full z-50' : ''}`}>
                <div className="max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="w-full flex items-center justify-between h-14">
                        <div className="flex items-center">
                            <a href="/" className="text-white font-bold">
                                <img src={logo} alt="" className="w-17 h-12" />
                            </a>
                        </div>
                        <div className="">
                            <ul className="flex space-x-4">
                                <li>
                                    <a href="/" className="block text-gray-800 hover:text-black">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="/" className="block text-gray-800 hover:text-black">
                                        Products
                                    </a>
                                </li>
                                <li>
                                    <a href="/" className="block text-gray-800 hover:text-black">
                                        Categories
                                    </a>
                                </li>
                                <li>
                                    <a href="/" className="block text-gray-800 hover:text-black">
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="flex items-center justify-end">
                            <div className="flex items-center mx-auto" x-data="{ search: '' }">
                                <div className="w-96 flex">
                                    <input
                                        type="search"
                                        className="w-full px-4 py-1 border-2 border-yellow-600 rounded-tl-md rounded-bl-md bg-white text-gray-800  focus:outline-none"
                                        placeholder="search"
                                        x-model="search"
                                    />
                                    <button className="bg-yellow-600 rounded-tr-md rounded-br-md w-12 items-center">
                                        <BsSearch className="w-12 h-8 p-2 text-white" />
                                    </button>
                                </div>
                            </div>
                            <button>
                                <BsCart className="w-12 h-8 text-yellow-600" />
                            </button>
                            {/* <div className="relative">
                                <button
                                    type="button"
                                    className="flex items-center justify-center w-7 h-7 text-purple-800 rounded-r-lg mr-2 relative"
                                    onClick={() => setDropdownOpen(!isDropdownOpen)}
                                >
                                    <VscAccount className="w-12 h-8 text-yellow-600" />
                                </button>
                                {isDropdownOpen && (
                                    <div className="fixed bg-white rounded-lg shadow-lg top-20 right-auto">
                                        <ul className="py-2">
                                            <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Option 1</li>
                                            <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Option 2</li>
                                            <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Option 3</li>
                                        </ul>
                                    </div>
                                )}
                            </div> */}

                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
