import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import skincare1 from "../assets/images/skincare-product-1.jpeg";
import skincare2 from "../assets/images/skincare-product-2.jpg";
import skincare3 from "../assets/images/skincare-product-3.jpg";
import n from "../assets/images/nnn.jpg";
import nn from "../assets/images/nnnn.jpg";
import {
  MdAddShoppingCart,
  MdFavoriteBorder,
  MdFavorite,
} from "react-icons/md";
import { setProducts } from "../features/products/productSlice";
import { setCategories } from "../features/category/categorySlice";
import { useFetchProductsQuery } from "../features/products/productApiSlice";
import { useFetchCategoryQuery } from "../features/category/categoryApiSlice";

const comments = [
  {
    id: 1,
    author: "User 1",
    comment: "Comment 1",
    date: "May 1, 2023",
    image: nn,
  },
  {
    id: 2,
    author: "User 2",
    comment: "Comment 2",
    date: "May 2, 2023",
    image: n,
  },
  {
    id: 3,
    author: "User 3",
    comment: "Comment 3",
    date: "May 3, 2023",
    image: skincare3,
  },
];

function Home() {
  const dispatch = useDispatch();
  const [favorite, setFavorite] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { data: productsdata, isLoading: productIsLoading } =
    useFetchProductsQuery();
  const { data: categorydata, isLoading } = useFetchCategoryQuery();
  const { products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    if (categories.length === 0 && categorydata) {
      dispatch(setCategories(categorydata));
    }
    if (products.length === 0 && productsdata) {
      dispatch(setProducts(productsdata));
    }
  }, [productsdata, categorydata, dispatch, products, categories]);

  const handleFavoriteClick = () => {
    setFavorite(!favorite);
  };

  const slides = [
    {
      imageUrl: skincare1,
      text: "Slide 1",
    },
    {
      imageUrl: skincare2,
      text: "Slide 2",
    },
    {
      imageUrl: skincare3,
      text: "Slide 3",
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(timer);
  }, []);
  console.log(categories);
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4 mx-4 py-3">
        <div className="col-span-1 lg:col-span-1 rounded-lg p-4 bg-white">
          <h2 className="text-xl font-bold mb-4">Categories</h2>
          <ul>
            {categories.slice(0, 6).map((category) => (
              <li key={category._id} className="mb-2">
                {category.name}
              </li>
            ))}
          </ul>

          <h2 className="text-xl font-bold mb-4">Blog</h2>
          {comments.map((comment) => (
            <div key={comment.id} className="mb-4">
              <div className="flex items-center mb-2">
                <img
                  src={comment.image}
                  alt={`Comment by ${comment.author}`}
                  className="w-12 h-12 rounded mr-2"
                />
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-lg font-semibold">{comment.author}</h3>
                    <p className="text-sm text-gray-500">{comment.date}</p>
                  </div>
                  <p>{comment.comment}</p>
                </div>
              </div>
            </div>
          ))}

          <h2 className="text-xl font-bold mt-8">Contact Us</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2">
                Name:
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-4 py-2 border border-gray-300 rounded"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block mb-2">
                Email:
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-4 py-2 border border-gray-300 rounded"
              />
            </div>

            <div className="mb-4">
              <label htmlFor="message" className="block mb-2">
                Message:
              </label>
              <textarea
                id="message"
                rows="4"
                className="w-full px-4 py-2 border border-gray-300 rounded"
              />
            </div>

            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="col-span-1 lg:col-span-4 gap-4">
          <div className="grid grid-cols-1 lg:grid-cols-4">
            <div className="col-span-1 lg:col-span-2">
              <div className="rounded-lg p-4 mb-2 bg-white">
                <h2 className="text-xl font-bold mb-4">Trending products</h2>
                <div className="grid grid-cols-3 gap-4">
                  {products.slice(0, 6).map((product, index) => {
                    const base64Image = btoa(
                      new Uint8Array(product.image.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ""
                      )
                    );
                    const imageUrl = `data:image/jpeg;base64,${base64Image}`;
                    return (
                      <div
                        key={index}
                        className="flex flex-col items-center"
                      >
                        <div className="rounded-full w-24 h-24 bg-purple-500 hover:bg-purple-600 border-2 hover:border-purple-600 hover:w-28 hover:h-28">
                          <img
                            src={imageUrl}
                            alt="Image"
                            className="rounded-full w-full h-full"
                          />
                        </div>
                        <p className="text-sm text-gray-500">Face wash</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-2 ">
              <div className="rounded-lg px-3 pt-1 mb-2">
                <div className="h-80">
                  <img
                    src={slides[currentSlide].imageUrl}
                    alt="Slideshow"
                    className="rounded-lg h-full object-cover"
                  />
                  <div className="absolute top-0 left-0  h-full flex items-center justify-between">
                    <button
                      onClick={prevSlide}
                      className="bg-yellow-500 bg-opacity-0 text-white px-4 py-2 rounded-full absolute top-1/2 transform -translate-y-1/2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={nextSlide}
                      className="relative bg-yellow-500 bg-opacity-0 text-white px-4 py-2 rounded-full top-1/2 transform -translate-y-1/2 right-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-5 w-5"
                      >
                        <circle cx="12" cy="12" r="10" />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 bg-white">
            <h2 className="text-xl font-bold mb-4">Recently added</h2>
            <div className="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-5 gap-4">
              {products.map((product, index) => {
                const base64Image = btoa(
                  new Uint8Array(product.image.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                  )
                );
                const imageUrl = `data:image/jpeg;base64,${base64Image}`;
                return (
                  <div
                    key={index}
                    className="bg-white border-2  rounded-lg"
                  >
                    <div className="relative">
                      <a
                        className="absolute top-0 right-0 p-2"
                        onClick={handleFavoriteClick}
                      >
                        {favorite ? (
                          <MdFavorite className="w-6 h-6 text-red-500" />
                        ) : (
                          <MdFavoriteBorder className="w-6 h-6 text-gray-500" />
                        )}
                      </a>

                      <img
                        src={imageUrl}
                        alt={product.name}
                        className="w-full h-48 object-cover rounded-t "
                      />
                    </div>
                    <div className="p-2">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-semibold">
                          {product.name}
                        </h3>
                        <p className="text-sm text-gray-500">Qty.{product.quantity}</p>
                      </div>
                      <p className="text-sm text-gray-500">
                        Product description
                      </p>
                      <p className="text-sm text-gray-800">MK11500.00</p>
                      <button className="bg-yellow-600 hover:bg-yellow-600 text-white font-bold py-1 px-4 rounded flex items-center w-full">
                        <MdAddShoppingCart className="mr-2" />
                        Add to Cart
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
