import api from "../../utils/api";

// Register a new user
const register = async (userData) => {
  const response = await api.post("user/register", userData);
  const user = response.data;
  // Store the user data in local storage or cookies if needed
  localStorage.setItem("user", JSON.stringify(user));
  return user;
};

// Sign in a user
const signIn = async (credentials) => {
  const response = await api.post("user/signin", credentials);
  const user = response.data;
  // Store the user data in local storage or cookies if needed
  localStorage.setItem("user", JSON.stringify(user));
  return user;
};

// Sign out the currently authenticated user
const signOut = async () => {
  // Remove the user data from local storage or cookies if needed
  localStorage.removeItem("user");
  // Perform any other necessary cleanup or API calls for signing out
};

const authService = {
  register,
  signIn,
  signOut,
};

export default authService;
