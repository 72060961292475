import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/apiSlice";
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/user/userSlice'
import shopReducer from '../features/shop/shopSlice'
import categoryReducer from '../features/category/categorySlice'
import productReducer from '../features/products/productSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    shops: shopReducer,
    products: productReducer,
    category: categoryReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
