import axios from 'axios';

const BASE_URL = 'https://project-alniya.onrender.com/api';

const api = {
  async get(endpoint) {
    try {
      const response = await axios.get(`${BASE_URL}/${endpoint}`);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}: ${error.message}`);
    }
  },

  async post(endpoint, data) {
    try {
      const response = await axios.post(`${BASE_URL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to post ${endpoint}: ${error.message}`);
    }
  },

  async put(endpoint, data) {
    try {
      const response = await axios.put(`${BASE_URL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to put ${endpoint}: ${error.message}`);
    }
  },

  async delete(endpoint) {
    try {
      const response = await axios.delete(`${BASE_URL}/${endpoint}`);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to delete ${endpoint}: ${error.message}`);
    }
  }
};

export default api;
