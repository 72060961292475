import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Category from "./pages/Category";
import Categories from "./pages/Categories";
import "react-toastify/dist/ReactToastify.css";
import CustomerLayout from "./components/CustomerLayout";
import DashboardLayout from "./components/DashboardLayout";
import AdminProducts from "./pages/AdminProducts";

function App() {
  const [count, setCount] = useState(0)

  return (
    <div className="app bg-slate-100">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <main className="content">
        <Routes>
          <Route path="/" element={<CustomerLayout> <Home /> </CustomerLayout>} />
          <Route path="/category" element={<DashboardLayout> <Category /> </DashboardLayout>} />
          <Route path="/categories" element={<CustomerLayout> <Categories /> </CustomerLayout>} />
          <Route path="/dashboard" element={<DashboardLayout> <Dashboard /> </DashboardLayout>} />
          <Route path="/adminproducts" element={<DashboardLayout> <AdminProducts /> </DashboardLayout>} />
        </Routes>
      </main>
    </div>
  )
}

export default App
