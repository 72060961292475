import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../features/category/categorySlice";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useFetchCategoryQuery } from "../features/category/categoryApiSlice";

export function CategorySelect({ category, onChange, defaultValue }) {
  const dispatch = useDispatch();
  const { data, isLoading } = useFetchCategoryQuery();
  const { categories } = useSelector((state) => state.category);

  useEffect(() => {
    if (categories.length === 0 && data) {
      dispatch(setCategories(data));
    }
  }, [data, dispatch, categories]);

  return (
    <FormControl sx={{ m: 1.5, width: "29ch" }}>
      <InputLabel>Category</InputLabel>
      <Select
        id="outlined-basic"
        label="Category"
        placeholder="Category"
        variant="outlined"
        size="small"
        name="category"
        value={category}
        defaultValue={defaultValue}
        onChange={onChange}
        required
      >
        {categories.map((category, index) => (
          <MenuItem key={index} value={category._id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
