import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addCategory } from "../../features/category/categorySlice";
import {
  useCreateCategoryItemMutation,
  useUpdateCategoryItemMutation,
} from "../../features/category/categoryApiSlice";
import {
  CircularProgress,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";

function CategoryForm({ category, onClose }) {
  const dispatch = useDispatch();
  const [createCategory, { isLoading: isCreating }] =
    useCreateCategoryItemMutation();
  const [updateCategoryData, { isLoading: isUpdating }] =
    useCreateCategoryItemMutation();
  const [formData, setFormData] = useState({
    name: "",
  });

  const { name } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (category) {
      const id = category._id;
      updateCategoryData({ id, data: formData })
        .then((res) => {
          dispatch(addCategory(res.data));
          toast.success("Operation success", {
            autoClose: 5000,
            theme: "light",
            onClose: onClose,
          });
        })
        .catch((error) => {
          // Handle errors here
          console.error(error);
        });
    } else {
      createCategory(formData)
        .then((res) => {
          dispatch(addCategory(res.data));
          toast.success("Operation success", {
            autoClose: 5000,
            theme: "light",
            onClose: onClose,
          });
        })
        .catch((error) => {
          // Handle errors here
          console.error(error);
        });
    }
  };

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1.5, width: "29ch" },
        "& .btn": { m: 1.5, width: "40ch" },
        "& .box": { m: 1.5, width: "40ch" },
        borderRadius: "5px",
      }}
    >
      <form onSubmit={onSubmit}>
        <TextField
          id="outlined-basic"
          label="Category Name"
          variant="outlined"
          size="small"
          name="name"
          value={name}
          onChange={onChange}
          required
        />
        <br />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isCreating || isUpdating}
          className="btn"
        >
          {isCreating || isUpdating ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </form>
    </Box>
  );
}

export default CategoryForm;
