import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImageUploader from "../ImageUploader";
import {
  addProduct,
  updateProduct,
} from "../../features/products/productSlice";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "../../features/products/productApiSlice";
import { CategorySelect } from "../SelectTextFields";
import { CircularProgress, Button, Box, TextField } from "@mui/material";

function ProductForm({ product, onClose }) {
  const dispatch = useDispatch();
  const [productPicture, setPicture] = React.useState(null);
  const [createProduct, { isLoading: isCreating }] = useCreateProductMutation();
  const [updateProductData, { isLoading: isUpdating }] =
    useUpdateProductMutation();
  const [formData, setFormData] = React.useState({
    name: "",
    price: 0,
    quantity: 0,
    description: "",
    category: "",
    image: null,
  });

  const { name, price, quantity, description, category, image } = formData;

  React.useEffect(() => {
    if (product) {
      setFormData({
        name: product.name,
        price: product.price,
        quantity: product.quantity,
        description: product.description,
        category: product.category,
        image: product.image,
      });
    }
  }, [product]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePictureChange = (file) => {
    setFormData((prevState) => ({ ...prevState, image: file }));
    setPicture(URL.createObjectURL(file));
    console.log(file);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("quantity", quantity);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("image", image);
    if (product) {
      const id = product._id;
      updateProductData({ id, data: formData })
        .then((res) => {
          dispatch(updateProduct(res.data));
          toast.success("Operation success", {
            autoClose: 5000,
            theme: "light",
            onClose: onClose,
          });
        })
        .catch((error) => {
          console.error(error);
          toast.error("Operation failed", {
            autoClose: 5000,
            theme: "light",
            onClose: onClose,
          });
        });
    } else {
      console.log(formData);
      createProduct(formData)
        .then((res) => {
          dispatch(addProduct(res.data.product));
          toast.success("Operation success", {
            autoClose: 5000,
            theme: "light",
            onClose: onClose,
          });
        })
        .catch((error) => {
          console.error(error);
          toast.error("Operation failed", {
            autoClose: 5000,
            theme: "light",
            // onClose: onClose,
          });
        });
    }
  };

  return (
    <Box
      sx={{
        "& .MuiTextField-root": { m: 1.5, width: "29ch" },
        "& .MuiSelect-root": { m: 1.5, width: "30ch" },
        "& .btn": { m: 1.5, width: "40ch" },
        "& .box": { m: 1.5, width: "29ch" },
        borderRadius: "5px",
      }}
    >
      <form onSubmit={onSubmit}>
        <TextField
          id="outlined-basic"
          label="Category Name"
          variant="outlined"
          size="small"
          name="name"
          value={name}
          onChange={onChange}
          required
        />
        <br />
        <CategorySelect
          value={category}
          defaultValue={product && product.category ? product.category : ""}
          onChange={onChange}
        />
        <br />
        <TextField
          id="outlined-basic"
          label="Price"
          variant="outlined"
          size="small"
          name="price"
          value={price}
          onChange={onChange}
          required
        />
        <br />
        <TextField
          id="outlined-basic"
          label="Quantity"
          variant="outlined"
          size="small"
          name="quantity"
          value={quantity}
          onChange={onChange}
          required
        />
        <br />
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          size="small"
          name="description"
          value={description}
          onChange={onChange}
          required
        />
        <br />
        <ImageUploader
          picture={productPicture}
          onPictureChange={handlePictureChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isCreating || isUpdating}
          className="btn"
        >
          {isCreating || isUpdating ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </form>
    </Box>
  );
}

export default ProductForm;
