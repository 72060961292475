import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Link,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  Breadcrumbs,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { IoIosSearch } from "react-icons/io";
import { BiSolidCategory } from "react-icons/bi";
import { setCategories } from "../features/category/categorySlice";
import { useFetchCategoryQuery } from "../features/category/categoryApiSlice";
import CategoryCard from "../components/cards/CategoryCard";
import CategoryForm from "../components/forms/CategoryForm";

function FormDialog({ onClose, open, category }) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Category</DialogTitle>
      <CategoryForm category={category} onClose={handleClose} />
    </Dialog>
  );
}

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function Category() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { data, isLoading } = useFetchCategoryQuery();
  const [searchTerm, setSearchTerm] = React.useState("");
  const { categories } = useSelector((state) => state.category);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  console.log(data);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = categories.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleDeleteCategory = (category) => {};

  useEffect(() => {
    if (categories.length === 0 && data) {
      dispatch(setCategories(data));
    }
  }, [data, dispatch, categories]);

  return (
    <Box p={4} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 64px)" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              position: "relative",
              marginRight: "16px",
              height: "40px",
              width: "40px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: "30%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgb(202, 138, 4 , 0.6 )",
                opacity: 0.1,
                borderRadius: "20%",
              }}
            ></Box>
            {<BiSolidCategory size={24} /> &&
              React.cloneElement(<BiSolidCategory size={24} />, {
                color: "rgb(202, 138, 4 , 1 )",
              })}
          </Box>
          <p className="text-gray-800 font-[600]">Categories</p>
        </Box>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Montserrat",
            backgroundColor: "rgb(202, 138, 4 , 1 )",
          }}
          onClick={handleClickOpen}
        >
          Add Category
        </Button>
        <FormDialog
          open={open}
          onClose={handleClose}
          category={selectedCategory}
        />
      </Box>
      <Box
        sx={{
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            borderRadius: "6px",
            background: "white",
            paddingX: "1rem",
            display: "flex",
            alignItems: "center",
            width: "30%",
            height: "45px",
          }}
        >
          <IoIosSearch
            size={16}
            style={{
              color: "rgb(18, 31, 67)",
            }}
          />
          <input
            type="text"
            placeholder="Find Category"
            style={{
              marginLeft: "5px",
              border: "none",
              outline: "none",
              paddingTop: "4px",
              paddingBottom: "5px",
              color: "rgb(18, 31, 67)",
              fontWeight: "600",
            }}
            onChange={handleSearch}
          />
        </Box>
      </Box>
      <Grid container spacing={2}>
        {filteredItems.map((item, index) => {
          return (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <CategoryCard
                icon={<BiSolidCategory size={32} />}
                name={item.name}
                onDelete={() => handleDeleteCategory(item.id)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Category;
