import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Dialog, DialogTitle, Button } from "@mui/material";
import PropTypes from "prop-types";
import { IoIosSearch } from "react-icons/io";
import {
  useFetchProductsQuery,
  useDeleteProductMutation,
} from "../features/products/productApiSlice";
import { TbBasketFilled } from "react-icons/tb";
import ProductForm from "../components/forms/ProductForm";
import ProductCard from "../components/cards/ProductCard";
import { setCategories } from "../features/category/categorySlice";
import { useFetchCategoryQuery } from "../features/category/categoryApiSlice";
import { setProducts, removeProduct } from "../features/products/productSlice";

function FormDialog({ onClose, open, product }) {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Product form</DialogTitle>
      <ProductForm product={product} onClose={handleClose} />
    </Dialog>
  );
}

FormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function AdminProducts() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { categorydata, isLoading } = useFetchCategoryQuery();
  const { products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.category);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const { data: productsdata, isLoading: productIsLoading } =
    useFetchProductsQuery();
  console.log(productsdata);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = products.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleDeleteCategory = (category) => {};

  useEffect(() => {
    if (categories.length === 0 && categorydata) {
      dispatch(setCategories(categorydata));
    }
    if (products.length === 0 && productsdata) {
      dispatch(setProducts(productsdata));
    }
  }, [productsdata, categorydata, dispatch, products, categories]);
  return (
    <Box p={6} sx={{ overflowY: "auto", maxHeight: "calc(100vh - 64px)" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              position: "relative",
              marginRight: "16px",
              height: "40px",
              width: "40px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: "30%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgb(202, 138, 4 , 0.6 )",
                opacity: 0.1,
                borderRadius: "20%",
              }}
            ></Box>
            {<TbBasketFilled size={24} /> &&
              React.cloneElement(<TbBasketFilled size={24} />, {
                color: "rgb(202, 138, 4 , 1 )",
              })}
          </Box>
          <p className="text-gray-800 font-[600]">Products</p>
        </Box>
        <Button
          variant="contained"
          style={{
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Montserrat",
            backgroundColor: "rgb(202, 138, 4 , 1 )",
          }}
          onClick={handleClickOpen}
        >
          Add Product
        </Button>
        <FormDialog
          open={open}
          onClose={handleClose}
          product={selectedProduct}
        />
      </Box>
      <Box
        sx={{
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            borderRadius: "6px",
            background: "white",
            paddingX: "1rem",
            display: "flex",
            alignItems: "center",
            width: "30%",
            height: "45px",
          }}
        >
          <IoIosSearch
            size={16}
            style={{
              color: "rgb(18, 31, 67)",
            }}
          />
          <input
            type="text"
            placeholder="Find Product"
            style={{
              marginLeft: "5px",
              border: "none",
              outline: "none",
              paddingTop: "4px",
              paddingBottom: "5px",
              color: "rgb(18, 31, 67)",
              fontWeight: "600",
            }}
            onChange={handleSearch}
          />
        </Box>
      </Box>
      <Grid container>
        {filteredItems.map((item, index) => {
          const base64Image = btoa(
            new Uint8Array(item.image.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          const imageUrl = `data:image/jpeg;base64,${base64Image}`;
          return (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ paddingLeft: "24px", paddingTop: "24px" }}
            >
              <ProductCard
                image={imageUrl}
                icon={<TbBasketFilled size={32} />}
                name={item.name}
                onDelete={() => handleDeleteCategory(item.id)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default AdminProducts;
