import { apiSlice } from "../apiSlice";

const CATEGORY_URL = "api/category";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCategory: builder.query({
      query: () => `${CATEGORY_URL}/`,
    }),
    createCategoryItem: builder.mutation({
      query: (data) => ({
        url: `${CATEGORY_URL}/`,
        method: "POST",
        body: data,
      }),
    }),
    updateCategoryItem: builder.mutation({
      query: ({ id, data }) => ({
        url: `${CATEGORY_URL}/update/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteCategoryItem: builder.mutation({
      query: (id) => ({
        url: `${CATEGORY_URL}/delete/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchCategoryQuery,
  useCreateCategoryItemMutation,
  useUpdateCategoryItemMutation,
  useDeleteCategoryItemMutation,
} = categoryApiSlice;
