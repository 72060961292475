import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-100 py-8">
      <div className="px-4 sm:px-2 lg:px-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
          {/* Section 1 */}
          <div>
            <h3 className="text-lg font-bold mb-4">ALNIYA INVESTMENTS</h3>         
            <p>For the look of the future</p>
          </div>

          <div>
            <h3 className="text-lg font-bold mb-4">My Account</h3>
            <ul className="">
              <li className="px-4 py-2">Profile</li>
              <li className="px-4 py-2">New account</li>
              <li className="px-4 py-2">Login</li>
              <li className="px-4 py-2">My order</li>
            </ul>
          </div>

          {/* Section 2 */}
          <div>
            <h3 className="text-lg font-bold mb-4">Information</h3>
            <ul className="">
              <li className="px-4 py-2">About us</li>
              <li className="px-4 py-2">Catalog</li>
              <li className="px-4 py-2">Contact us</li>
              <li className="px-4 py-2">Terms & Conditions</li>
            </ul>
          </div>

          {/* Section 3 */}
          <div>
            <h3 className="text-lg font-bold mb-4">Quick Link</h3>
            <ul className="">
              <li className="px-4 py-2">Help Center</li>
              <li className="px-4 py-2">Report</li>
              <li className="px-4 py-2">FaQs</li>
            </ul>
          </div>

          {/* Section 4 */}
          <div>
            <h3 className="text-lg font-bold mb-4">Subscribe to Newsletter</h3>
            <form>
              <input
                type="email"
                placeholder="Email address"
                className="px-4 py-2 rounded-lg bg-white focus:outline-none"
              />
              <button
                type="submit"
                className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg mt-4"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
