import React, { useState } from "react";
import { RiUploadCloud2Fill } from "react-icons/ri";
import { Box } from "@mui/material";

function ImageUploader({ picture, onPictureChange }) {
  const [dragState, setDragState] = useState("none"); // Track the drag state

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragState("drag-enter");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragState("drag-over");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragState("none");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragState("none");

    const file = e.dataTransfer.files[0];
    onPictureChange(file);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    onPictureChange(file);
  };

  return (
    <Box
      component="label"
      className="box"
      variant="text"
      sx={{
        flex: "1 0 auto",
        height: "150px",
        border: "1px dashed",
        borderRadius: "5px",
        background: "rgb(140, 163, 186, 0.2)",
        borderColor: "rgb(140, 163, 186, 0.6)",
        color: "rgb(140, 163, 186, 0.6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "20px",
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!picture ? (
        <>
          <RiUploadCloud2Fill size={32} />
          <p style={{ padding: "0px", margin: "0px" }}>
            {dragState === "drag-over" ? "Drop the image" : "Upload Picture"}
          </p>
          <input
            hidden
            accept="image/*"
            name="picture"
            onChange={handleImageChange}
            type="file"
          />
        </>
      ) : (
        <>
          <img
            src={picture}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "90%",
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
          <p style={{ padding: "0px", margin: "0px" }}>
            {dragState === "drag-over"
              ? "Drop the image"
              : "Upload New Picture"}
          </p>
          <input
            hidden
            accept="image/*"
            name="picture"
            onChange={handleImageChange}
            type="file"
            id="imageInput"
          />
        </>
      )}
    </Box>
  );
}

export default ImageUploader;
