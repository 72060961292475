import React, { useState } from "react";
import { Box, useTheme, Button, Paper, Popover } from "@mui/material";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { IoChevronForwardOutline, IoCloseCircleOutline } from "react-icons/io5";
import { HiDotsVertical } from "react-icons/hi";
import { RxDragHandleDots2 } from "react-icons/rx";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { MdOutlineCastForEducation } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { BsPencil } from "react-icons/bs";

// Reusable Card component
function CategoryCard({
  icon,
  name,
  curriculum,
  date,
  color,
  onClick,
  onDelete,
  onEdit,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleEditClick = (event) => {
    event.stopPropagation(); // Prevent click event propagation to the parent Card component
    onEdit();
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation(); // Prevent click event propagation to the parent Card component
    onDelete();
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  return (
    <Paper
      onClick={onClick}
      style={{ cursor: "pointer", padding: "20px", borderRadius: "10px" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display={"flex"} sx={{ alignItems: "center" }}>
          <p style={{ color: "#212B36", fontWeight: "600", fontSize: "1rem" }}>
            {name}
          </p>
        </Box>
        <Box>
          <HiDotsVertical
            color={color}
            size={24}
            style={{ marginRight: "6px" }}
            onClick={handleMenuClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper style={{ padding: 5 }}>
              <Button variant="text" color="error" onClick={handleDeleteClick}>
                Delete
              </Button>
              <br />
              <Button variant="text" onClick={handleEditClick} color="info">
                Update
              </Button>
            </Paper>
          </Popover>
        </Box>
      </Box>
      <Box>
        <p
          style={{
            color: "rgb(145, 158, 171)",
            fontWeight: "400",
            fontSize: "0.75rem",
            marginTop: "8px",
          }}
        >
          {curriculum}
        </p>
        <p
          style={{
            color: "rgb(145, 158, 171)",
            fontWeight: "400",
            fontSize: "0.75rem",
            marginTop: "8px",
          }}
        >
          {date}
        </p>
      </Box>
    </Paper>
  );
}

export default CategoryCard;
