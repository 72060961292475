import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://project-alniya.onrender.com/";

const baseQuery = fetchBaseQuery({ baseUrl });

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: ["users"],
  endpoints: (builder) => ({}),
});
