import * as React from "react";
import {
  Box,
  IconButton,
  useTheme,
  Popover,
  Typography,
  Button,
  Paper,
  Link,
} from "@mui/material";
import { useContext } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import { CiDark, CiLight } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { AiOutlineLogout } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { MdNotifications } from "react-icons/md";

function Topbar() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (event) => {};

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      sx={{ height: "70px" }}
    >
      <Box display="flex" justifyContent="flex-end" flexGrow={1}>
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <CiDark />
          ) : (
            <CiLight />
          )}
        </IconButton> */}
      </Box>
      <Box display="flex">
        <IconButton style={{  }}>
          <MdNotifications
            color="rgb(140, 163, 186)"
            size={24}
            style={{ padding: 2 }}
          />
        </IconButton>
        <IconButton style={{ marginRight: "10px" }}>
          <BsFillGrid3X3GapFill
            color="rgb(140, 163, 186)"
            size={24}
            style={{ padding: 2 }}
          />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid rgb(211, 230, 243)", // Specify border style explicitly
            borderRadius: "20px",
            paddingLeft: "10px",
          }}
        >
          <Typography sx={{ color: "#5F748D" }}>
            Hello, <span style={{ fontWeight: "600" }}>admin</span>
          </Typography>
          <IconButton onClick={handleClick}>
            <CgProfile color="rgb(140, 163, 186)" size={24} />
          </IconButton>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper style={{ padding: 5 }}>
            <Typography sx={{ p: 2 }}>Profile</Typography>
            <Button
              variant="text"
              onClick={handleLogout}
              startIcon={<AiOutlineLogout />}
              sx={{ width: "100%" }}
              color="error"
            >
              Log out
            </Button>
          </Paper>
        </Popover>
      </Box>
    </Box>
  );
}

export default Topbar;
