import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shops: [],
  loading: false,
  error: null,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShops: (state, action) => {
      state.shops = action.payload;
    },
    addShop: (state, action) => {
      state.shops.push(action.payload);
    },
    updateShop: (state, action) => {
      const updatedShop = action.payload;
      const index = state.shops.findIndex(
        (shop) => shop.id === updatedShop.id
      );
      if (index !== -1) {
        state.shops[index] = updatedShop;
      }
    },
    removeShop: (state, action) => {
      const shopId = action.payload;
      state.shops = state.shops.filter((shop) => shop.id !== shopId);
    },
  },
});

export const { setShops, addShop, updateShop, removeShop } = shopSlice.actions;

export default shopSlice.reducer;
